import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { media } from '@dfds-ui/theme'
import { Headline } from '@dfds-ui/react-components'

import ContentEntry from './ContentEntry'
import { isContentfulType } from '../../utils'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  ${media.greaterThan('l')`
    flex-direction: row;
  `}

  > div {
    width: 100%;
    margin-bottom: 40px;

    ${media.greaterThan('l')`
      &[md="8"], &[lg="8"] {
        width: calc(66.66% - 20px);
      }
      &[md="6"], &[lg="6"] {
        width: calc(50% - 20px);
      }
      &[md="4"], &[lg="4"] {
        width: calc(33.33% - 26.66px);
      }
      &[md="3"], &[lg="3"] {
        width: calc(25% - 30px);
      }
    `}
  }
`

const Container = ({ title, children, ...rest }) => {
  return (
    <Wrapper {...rest}>
      {title && (
        <Headline
          as={'h2'}
          css={css`
            width: 100%;
            margin-left: 20px;
            margin-right: 20px;

            ${media.greaterThan('m')`
              margin-left: 0;
              margin-right: 0;
            `}
          `}
        >
          {title}
        </Headline>
      )}
      {children}
    </Wrapper>
  )
}

const ContentRows = ({ contentRows: { items = [] } }) => {
  return items.map(({ contentEntryCollection, title, sys, __typename }) => {
    if (
      isContentfulType(__typename, 'contentful_LocalizedRow') &&
      contentEntryCollection
    ) {
      return (
        <Container key={sys.id} id={sys.id} title={title}>
          <ContentEntry {...contentEntryCollection} />
        </Container>
      )
    }
    if (!contentEntryCollection || !contentEntryCollection.items) {
      return null
    }
    const { items = [] } = contentEntryCollection
    return (
      <Container key={sys.id} id={sys.id} title={title}>
        {items.map((item) => {
          return <ContentEntry key={item.sys.id} {...item} />
        })}
      </Container>
    )
  })
}

export const query = graphql`
  fragment CommonContentRows on contentful_ContentRow {
    __typename
    sys {
      id
    }
    title
  }

  fragment LocalizedRow on contentful_LocalizedRow {
    __typename
    sys {
      id
    }
    title
    contentEntryCollection: linkToEntry {
      ...PageContentAccordionList
      ...LinkboxCarousel
    }
  }

  fragment ContentRows on contentful_ContentRow {
    ...CommonContentRows
    contentEntryCollection(limit: 3) {
      items {
        ... on contentful_Entry {
          __typename
          sys {
            id
          }
        }
        ...PageContentTextSection
        ...PageContentTextWithLanguageSwitcher
        ...TextAndMediaLayout
        ...InfoBlock
        ...PromoBlock
        ...IconBlockList
        ...FreightRoutesList
        ...BioPromo
        ...ContactBox
        ...PageContentAccordionList
        ...MapLayout
        ...NewsletterSubscribeBlock
        ...Trustpilot
        ...Tagbox
      }
    }
  }

  fragment ContentRowsSecond on contentful_ContentRow {
    ...CommonContentRows
    contentEntryCollection(limit: 3) {
      items {
        ... on contentful_Entry {
          __typename
          sys {
            id
          }
        }
        ... on contentful_XSpaceRefComponent {
          component
        }
        ...ShareChartSet
        ...SharePriceBlock
        ...ChartSet
        ...ExternalWidget
        ...IframeBlock
        ...MediaLibraryEntry
        ...NewsArticleList
        ...TextAndMediaLayout
        ...LinkboxCarousel
        ...PaxTimeTable
      }
    }
  }

  fragment ContentFormRows on contentful_ContentRow {
    ...CommonContentRows
    contentEntryCollection(limit: 3) {
      items {
        ... on contentful_Entry {
          __typename
          sys {
            id
          }
        }
        ... on contentful_LocalisationWrapper {
          localisedContentReference {
            ... on contentful_Entry {
              __typename
              sys {
                id
              }
            }
            ...FormContactUs
            ...FormForgetMe
            ...NewsletterSubscribeBlock
            ...NewsletterUnsubscribeBlock
            ...NewsArticleList
          }
        }
      }
    }
  }
`

export default ContentRows
